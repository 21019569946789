import axios from "axios";

export default {
  async get() {
    return await axios
      .get("irmc/getQuestions")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async create(req) {
    return await axios
      .post("irmc/addQuestion", req)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async removeQstn(id) {
    return await axios
      .delete("irmc/removeQuestion/" + id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
};
